import React from 'react';
import {classes} from './Summary.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {TotalsSection} from './TotalsSection/TotalsSection';
import {SlotId} from '../../../domain/utils/slotId';
import {CheckoutSlot} from '../CheckoutSlot';
import {CouponInput} from './CouponInput/CouponInput';
import {GiftCardInput} from './GiftCardInput/GiftCardInput';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SummaryLocation} from '../../../types/checkoutApp.types';
import {SummaryTitle} from './SummaryTitle/SummaryTitle';
import {LineItemSection} from './LineItemSection/LineItemSection';
import {TopMobileSummary} from './TopMobileSummary';
import {SecureCheckout} from '../../../common/components/SecureCheckout/SecureCheckout';
import {SPECS} from '../../../common/constants';

export enum SummaryDataHooks {
  couponSectionInput = 'SummaryDataHooks.couponSectionInput',
  giftCardSectionInput = 'SummaryDataHooks.giftCardSectionInput',
  violations = 'SummaryDataHooks.violations',
}

export interface SummaryProps {
  location: SummaryLocation;
  dataHook: string;
  checkboxesRef?: React.RefObject<HTMLDivElement>;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Summary = ({dataHook, location}: SummaryProps) => {
  const {
    checkoutStore: {shouldShowGiftCardSection, shouldShowCouponSection, isFastFlow},
    isOneColumnView,
  } = useControllerProps();
  const {experiments} = useExperiments();

  const addSlotToCheckout = !isFastFlow && experiments.enabled(SPECS.AddSlotToCheckout);
  const shouldMoveSlotAboveCheckoutButton = experiments.enabled(SPECS.MoveSlotAboveCheckoutButton);

  let className = classes.stickyOld;
  /* istanbul ignore next */
  const rootMobileClass =
    addSlotToCheckout && shouldMoveSlotAboveCheckoutButton ? classes.rootMobile : classes.rootMobileSlotBelowCheckout;

  if (!isFastFlow) {
    className =
      location === SummaryLocation.paymentAndPlaceOrderStep ? /* istanbul ignore next */ rootMobileClass : classes.root;
  }

  return (
    <div className={isOneColumnView ? classes.sticky : className} data-hook={dataHook}>
      {isOneColumnView ? (
        <TopMobileSummary />
      ) : (
        <>
          {addSlotToCheckout && (
            /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.BeforeSummaryCheckoutSlot} />
          )}
          <div className={classes.summary}>
            <SummaryTitle />
            <LineItemSection />
            {addSlotToCheckout && (
              /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.AfterLineItemsSummaryCheckoutSlot} />
            )}
            {addSlotToCheckout && (
              /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.AfterLineItems2SummaryCheckoutSlot} />
            )}
            <div className={classes.couponAndGiftCard}>
              {shouldShowCouponSection && <CouponInput />}
              {shouldShowGiftCardSection && <GiftCardInput />}
            </div>
            {addSlotToCheckout && (
              /* istanbul ignore next: test slot */ <CheckoutSlot
                slotId={SlotId.BeforeTotalsBreakdownSummaryCheckoutSlot}
              />
            )}
            <TotalsSection />
          </div>
          <SecureCheckout />
          {addSlotToCheckout && (
            /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.AfterSummaryCheckoutSlot} />
          )}
        </>
      )}
    </div>
  );
};
