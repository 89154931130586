import {useControllerProps} from './Widget/ControllerContext';
import React, {useEffect} from 'react';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {SlotId} from '../../domain/utils/slotId';
import {usePaymentsApi} from '../../common/components/WithPaymentsApi/WithPaymentsApi';
import {PaymentMethod} from '@wix/cashier-payments-widget';

export const CheckoutSlot = /* istanbul ignore next: test slot */ ({slotId}: {slotId: SlotId}) => {
  const {
    slotsStore: {setSlotsParams, setStepId, setPaymentId},
    stepsManagerStore: {activeStep},
  } = useControllerProps();

  const {activePaymentId} = usePaymentsApi();

  useEffect(
    () => {
      setSlotsParams(slotId);
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );

  useEffect(() => {
    setStepId(slotId, activeStep.stepId);
  }, [activeStep]);

  useEffect(() => {
    setPaymentId(slotId, activePaymentId as PaymentMethod);
  }, [activePaymentId]);

  return <SlotsPlaceholder slotId={slotId} />;
};
