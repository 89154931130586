import React from 'react';
import {TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {st, classes} from './Header.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {CheckoutSlot} from '../CheckoutSlot';
import {SlotId} from '../../../domain/utils/slotId';
import {StoreNameAndLogo} from '../../../common/components/StoreNameAndLogo/StoreNameAndLogo';
import {SPECS} from '../../../common/constants';

export enum HeaderDataHooks {
  ContinueShoppingButton = 'HeaderDataHooks.ContinueShoppingButton',
}

export const Header = ({dataHook}: {dataHook: string}) => {
  const {isOneColumnView} = useControllerProps();
  const localKeys = useLocaleKeys();
  const {
    checkoutSettingsStore: {checkoutSettings, checkoutContent},
    navigationStore: {continueShoppingUrl, clickOnContinueShopping, isContinueShoppingEnabled},
  } = useControllerProps();
  const {experiments} = useExperiments();
  const addSlotToCheckout = experiments.enabled(SPECS.AddSlotToCheckout);
  const continueShoppingText = checkoutContent?.continueShoppingLink ?? localKeys.checkout.continue_shopping();
  const shouldUseCheckoutHeaderOnCheckout = experiments.enabled(SPECS.UseCheckoutHeaderOnCheckout);

  return (
    <header className={classes.header}>
      <div
        className={st(shouldUseCheckoutHeaderOnCheckout ? classes.innerHeaderFix : classes.innerHeader)}
        data-hook={dataHook}>
        <StoreNameAndLogo
          continueShoppingUrl={continueShoppingUrl}
          clickOnContinueShopping={clickOnContinueShopping}
          logoUrl={checkoutSettings.logoUrl}
          storeName={checkoutSettings.storeName}
          checkoutHeader={checkoutSettings.checkoutHeader}
          isOneColumnView={isOneColumnView}
        />
        {isContinueShoppingEnabled && (
          <TextButton
            as={'a'}
            className={
              shouldUseCheckoutHeaderOnCheckout ? classes.continueShoppingButton : classes.continueShoppingButtonOld
            }
            data-hook={HeaderDataHooks.ContinueShoppingButton}
            href={continueShoppingUrl}
            onClick={() => clickOnContinueShopping()}
            priority={TextButtonPriority.link}>
            {continueShoppingText}
          </TextButton>
        )}
      </div>
      <div className={classes.divider} />
      {/* istanbul ignore next: test slot */ addSlotToCheckout && <CheckoutSlot slotId={SlotId.HeaderCheckoutSlot} />}
    </header>
  );
};
